<template>
  <navm :list="data.navlist" :funone="functions"/>
  <div class="enboxheader">
    <img :src="data.baseUrl+'uploads/images/95f51a39412144e6c860210704dd896a.png'" class="left_log"  @click="router.push('/en')">
    <ul class="rightText">
      <li @click.stop="openRouter('ContactUs')">Contact Us</li>
      <li>|</li>
      <li @click="openRouter('https://english.ucas.ac.cn/')">UCAS</li>
      <li>|</li>
      <li>{{getDate()}}</li>
      <li>|</li>
      <li @click="router.push('/')">CHINESE</li>
      <li><img :src="data.baseUrl+'uploads/images/0d09dd65f48e264e1de046cc30111f65.png'" @click="enSearch"><input type="text" v-model="data.keywords" @keydown="enter(2,$event)" @blur="enter(1,$event)" placeholder="Search"></li>
    </ul>
    <div class="bottom">
      <ul class="bottomlist" ref="bottomlist">
        <!-- 递归组件 -->
        <menu-item v-for="(item,i1) in data.navlist" :key="item" :model="item" :fun="functions"></menu-item>
      </ul>
    </div>
  </div>
</template>

<script setup>
import navm from '@/components/phonenavmenu.vue'
import menuItem from '@/components/englishCom/menu.vue'
import * as http from '@/api/API.js'
import miiter from '@/utils/miitbus.js'
import { ref,onMounted, reactive } from 'vue'
import { useRouter,useRoute } from 'vue-router'
const router = useRouter()
const route = useRoute()
const data = reactive({
  baseUrl:process.env.VUE_APP_BASE_URL,
  navlist:[],
  keywords:''
})

const bottomlist = ref(null)

// 鼠标移入最后一个li把二级菜单向左移动。防止溢出
const ullastli = (e) => {
 const li = bottomlist.value.children
 li[e].children[1].style.left = -150/128+'rem'
}
// 获取头部菜单栏
const getnav = () => {
  http.getNavlist({is_en:1,is_banner_nav:1}).then(res => {
    if(res.data.code == 200){
      data.navlist = res.data.data
    }
  })
}
onMounted(() => { 
  getnav()
})
// 有三级，直接跳转三级
const menu3 = (ee) => {
  router.push({
      name: ee.vue_url,
      params:{
      id:ee.id,
      tid:ee.tid,
      level:ee.level
      }
    })
    miiter.emit('onEnactilcetap')
}
// 通过props传递函数,跳转对应
const functions = (e) => {
  // console.log(e);return
   // 如果有外部链接就跳转
  if(e.external_url){
    window.open(e.external_url)
    return
  }
  if(e.template_type == 1){
    if(e.children[0].children?.length>0){menu3(e.children[0].children[0]);return}
    router.push({
      name: e.children[0].vue_url,
      params:{
      id:e.children[0].id,
      tid:e.children[0].tid,
      level:e.children[0].level
      }
    })
    miiter.emit('onEnactilcetap')
    return
  }
  router.push({
    name: e.vue_url,
    params:{
      id:e.id,
      tid:e.tid,
      level:e.level
    }
  })
  miiter.emit('onEnactilcetap')
}
// 处理顶部显示的时间日期
const getDate = () => {
  const monthArr = ['January','February','March' ,'April' ,'May' ,'June' ,'July' ,'August','September', 'October','November','December']
  const date = new Date()
  const getFullYear = date.getFullYear()
  const getMonth = date.getMonth()
  const getDate = date.getDate()
  const timeStr = `${monthArr[getMonth]}${'\n'}${getDate}${','}${'\n'}${getFullYear}`
  return timeStr
}
// 点击搜索
import { ElNotification  } from 'element-plus'
const enSearch = () => {
  if(data.keywords.trim()){
    if(route.name == 'Ensearch'){
    window.sessionStorage.setItem("key",JSON.stringify(data.keywords))
    miiter.emit('enseachKeyword',data.keywords)
    return
    }
    router.push({
    name:'Ensearch',
    params:{
      id:313,
      tid:303,
      level:2
    }
  })
  window.sessionStorage.setItem("key",JSON.stringify(data.keywords))
  miiter.emit('enseachKeyword',data.keywords)
  }else{
    ElNotification({
    title: 'Warning',
    message: 'The search content cannot be empty！',
    type: 'warning',
    zIndex:9999999999999
  })
  }
}
// 监听回车事件
const enter = (n,e)=> {
  if(n == 1){
    enSearch()
    return
  }
  if(e.keyCode === 13){
    enSearch()
  }
}
// 跳转第三方
const openRouter = (e) =>{
  if(e == 'ContactUs'){
    router.push({
      name: 'Enarticlelist',
      params:{
        id:316,
        tid:303,
        level:2
      }
  })
  return
  }
  window.open(e)
}
</script>

<style lang="less" scoped>
@maxw:128rem;
  .enboxheader{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    // z-index: 99999999999;
    z-index: 99999;
    width: 1920/@maxw;
    height: 215/@maxw;
    background:linear-gradient(to left,#174994,#0C254A);
    .left_log{
      width: 584/@maxw;
      height: 76/@maxw;
      vertical-align: middle;
      margin: 41/@maxw 0 0 70/@maxw;
    }
    .rightText{
      position: absolute;
      right: 72/@maxw;
      top: 61/@maxw;
      cursor: pointer;
      display: flex;
      align-items: center;
      color:#DDCEC0;
      font-size: 18/@maxw;
      font-family: 'Microsoft YaHei';
      >li:nth-child(2n){  
        margin: 0 20/@maxw;
      }
      >li:nth-child(7){
        font-weight: bold;
      }
      >li:last-child{
        position: relative;
        margin-left: 40/@maxw;
        margin-right: 0;
        width: 271/@maxw;
        height: 36/@maxw;
        display: flex;
        >img{
          position: absolute;
          left: 12/@maxw;
          top: 50%;
          transform: translateY(-50%);
          width: 19/@maxw;
          height: 19/@maxw;
        }
        >input{
          width: 100%;height: 100%;
          border: none;
          border-radius: 50/@maxw;
          padding-left: 40/@maxw;
          font-size: 18/@maxw;
          &::-webkit-input-placeholder {
	        /* WebKit, Blink, Edge */
	        color: #CBCBCB;
          }
        }
      }
    }
    .bottom{
      position: absolute;
      cursor: pointer;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 1778/@maxw;
      height: 58/@maxw;
      color: #174994;
      background:linear-gradient(150deg, #DDCEC0 50%, #B19A86 100%);
      .bottomlist{
        width: 100%;
        font-size: 19/@maxw;
        display: flex;
        align-items: center;
      }
    }
  }
@media screen and (max-width:750px) {
  .enboxheader{
    display:none;
  }
}
</style>
