<template>
    <li @click.stop="ontopclick(props.model)">
        <div class="titleicon">
            <span>{{ props.model.title_en }}</span>
            <img src="@/assets/enhomeicon.png" v-if="props.model.children && props.model.level != 1">
        </div>
        <div v-if="props.model.children" class="twomenu">
            <menuItems v-for="(item, index) in props.model.children" :model="item" :fun="props.fun" :key="index"></menuItems>
        </div>
    </li>
</template>

<script>
export default {
    name: 'menuItems',
}
</script>
<script setup>
import { defineProps,ref} from 'vue';
const props = defineProps({
    model:[],
    fun:Function
})
// const emits = defineEmits(['onheader'])
const ontopclick = (e) => {
    props.fun(e)
}

</script>

<style lang="less" scoped>
@maxw: 128rem;
li{
    position: relative;
    margin-left: 60/@maxw;
    height: 58/@maxw;
    // line-height: 58/@maxw;
    display: flex;
    align-items: center;
    transition: ease .1s;
    font-family: 'Microsoft YaHei';
    >.titleicon{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 19/@maxw;
        >span{
            transition: ease .2s;
        }
        >img{
            width: 9/@maxw;
            height: 15/@maxw;
            vertical-align: middle;
            margin-right: 19/@maxw;
        }
    }
    >.twomenu{
        position: absolute;
        z-index: 9999999999999 !important;
        top: 54/@maxw;
        left: -20/@maxw;
        width: 290/@maxw;
        background-color: #174994;
        border-top: 4/@maxw solid #174994;
        opacity: .95;
        transform:scale(0);
        transition: ease .2s;
         >li{
              transition: ease .1s;
              box-sizing: border-box;
              padding: 0 0 0 27/@maxw;
              margin: 0;
              width: 100%;
            //   height: 100%;
            height: 58/@maxw;
              color: #fff;
              font-family: 'Microsoft YaHei';
              >.titleicon span{
                width: 90%;
                height: 100%;
                display: flex;
                justify-content: start;
                align-items: center;
                // overflow:hidden;
                // white-space: nowrap;
                // text-overflow: ellipsis;
                // -o-text-overflow:ellipsis;
              }
              .twomenu{
                top: -4/@maxw;
                left: 290/@maxw;
              }
         }
         >li:hover{
              background-image: url('../../assets/endactive.png');
              background-size: 100% 100%;
              color: #0D2F62;
         }
    }
    }
li:first-child{
  margin:0 0 0 35/@maxw;
}
li:hover{
    >.titleicon{
      span{
        text-shadow: 1px 0 0 currentColor;
      }
    }
    >.twomenu{
      transform:scale(1);
    }
}
</style>
